/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

.ngx-dropdown-container .ngx-dropdown-list-container .selected-items {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ngx-dropdown-container .ngx-dropdown-list-container .available-items 
{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}


.ngx-dropdown-container .ngx-dropdown-list-container .select-options [type="checkbox"] + span:not(.lever) {
  line-height: 15px !important;
  height: 15px !important;
  padding-left: 8px !important;
}


.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item
{
  border: 1px solid #337ab7;
  margin-right: 4px;
  background: #337ab7;
  padding: 0 5px;
  margin-bottom: 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  max-width: 100% !important;
}